import React from "react";
export const AppLoading = (props) => {
  return (<span style={{
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-70px",
    marginLeft: "-70px",
    width: "150px",
    height: "100px",

  }}>
    <img src="/logo/chicca-vertical-logo.webp" width={150} style={{ width: "150px" }}></img>
    {/* {!props.isFirstUse && <LinearProgress color="primary" />} */}
  </span>)
}



export default (AppLoading);

