import { ACTIONS } from '../utils/actions';


const addToHomeReducer = (state = false, action) => {
    switch (action.type) {
        case ACTIONS.ADD_TO_HOME:
            return action.payload
        default: return state;
    }
}

export default addToHomeReducer;