
import { isJsonObjectEmpty } from '../utils/common';

import { ACTIONS } from '../utils/actions';
const callReducer = (state = { isEnded: true, calling: false, someOneCalling: false, status: "", start: "", end: "", isLimit: false, showNotif: false }, action) => {
    switch (action.type) {
        case ACTIONS.CALL:
            if (isJsonObjectEmpty(action.payload)) {
                return { isEnded: true, calling: false, someOneCalling: false, status: "", start: "", end: "", isLimit: false, showNotif: false }
            }
            return { ...state, ...action.payload };
        default: return state;
    }
}

export default callReducer;