import store from '../store';
import { getCurrentTime, getConvertedTime } from '../utils/common';

import { ACTIONS } from '../utils/actions';
import { CALL_MESSAGE_RULE } from '../utils/service';
import { uniqBy } from '@s-libs/micro-dash';

const smsReducer = (state = [], action) => {

    var newMessages = []
    var newSms = {}
    var smsHistory, smsHistoryIndex, index, sms, result

    switch (action.type) {
        case ACTIONS.SMS:
            action.payload.date = action.payload.messages[action.payload.messages.length - 1].date
            // console.log(action, 'smsaction')

            // console.log(state, 'hello -state')
            if (!state || state.length == 0) {
                newMessages.push(action.payload)
                newSms = {
                    owner: action.owner,
                    sms: newMessages
                }
                state.push(newSms)

            }
            else {

                // console.log(state, 'hello -state')
                smsHistory = state.find(x => x.owner == action.owner)
                smsHistoryIndex = state.findIndex(x => x.owner == action.owner)

                if (smsHistory) {
                    sms = smsHistory.sms;
                    index = smsHistory.sms.findIndex(x => x.peerId == action.payload.peerId && x.adId == action.payload.adId)
                    if (index >= 0) {
                        sms[index].distance = action.payload.distance
                        sms[index].isRead = action.payload.isRead
                        sms[index].timeSend = action.payload.timeSend
                        sms[index].messages.push(...action.payload.messages)
                        sms[index].messages = uniqBy(sms[index].messages, function (e) {
                            return e.msgId + "";
                        });
                        sms[index].date = action.payload.date;

                    } else {
                        sms.push(action.payload)
                    }
                    smsHistory.sms = sms;
                    state[smsHistoryIndex] = smsHistory
                }
                else {
                    newMessages.push(action.payload)
                    newSms = {
                        owner: action.owner,
                        sms: newMessages
                    }
                    state.push(newSms)
                }
            }

            // console.log(state, 'hello sms action')

            result = JSON.parse(JSON.stringify(state))

            return result;
        case ACTIONS.SMS_REMOVE:
            if (state.length == 0) return state;
            smsHistory = state.find(x => x.owner == action.owner)
            smsHistoryIndex = state.findIndex(x => x.owner == action.owner)


            index = smsHistory.sms.findIndex(x => x.peerId == action.payload.peerId && x.adId == action.payload.adId)
            if (index >= 0) {
                sms = smsHistory.sms;
                var messagesLastIndex = sms[index].messages.length - 1;
                var payloadLastMessage = action.payload.messages.length - 1
                if (sms[index].messages[messagesLastIndex].date === action.payload.messages[payloadLastMessage].date) {
                    sms.splice(index, 1)
                }

                smsHistory.sms = sms
                state[smsHistoryIndex] = smsHistory
            }

            // console.log(state, 'hello sms remove')

            result = JSON.parse(JSON.stringify(state))
            return result;
        case ACTIONS.SMS_UPDATE_DISTANCE:
            if (state.length == 0) return state;
            smsHistory = state.find(x => x.owner == action.owner)
            smsHistoryIndex = state.findIndex(x => x.owner == action.owner)
            if (smsHistory) {
                index = smsHistory.sms.findIndex(x => x.peerId == action.payload.peerId && x.adId == action.payload.adId)
                if (index >= 0) {
                    sms = state.sms;
                    smsHistory.sms[index].distance = action.payload.distance
                    state[smsHistoryIndex] = smsHistory
                }
            }

            // console.log(state, 'hello sms markRead')

            result = JSON.parse(JSON.stringify(state))
            return result;
        case ACTIONS.SMS_MARK_READ:
            // console.log(action,'hello')

            // console.log(state,'hello')

            if (state.length == 0) return state;
            smsHistory = state.find(x => x.owner == action.owner)
            smsHistoryIndex = state.findIndex(x => x.owner == action.owner)
            if (smsHistory) {
                index = smsHistory.sms.findIndex(x => x.peerId == action.payload.peerId && x.adId == action.payload.adId)
                if (index >= 0) {
                    sms = state.sms;
                    smsHistory.sms[index].isRead = true
                    state[smsHistoryIndex] = smsHistory
                }
            }

            // console.log(state, 'hello sms markRead')

            result = JSON.parse(JSON.stringify(state))
            return result;
        case ACTIONS.SMS_MARK_SENT:

            //// console.log(state, 'hello yhey')
            if (!state) return state;
            smsHistory = state.find(x => x.owner == action.owner)
            smsHistoryIndex = state.findIndex(x => x.owner == action.owner)

            if (smsHistory) {
                //// console.log('hello hey')
                index = smsHistory.sms.findIndex(x => x.peerId == action.payload.peerId && x.adId == action.payload.adId)

                // console.log(index, 'hello hi')
                if (index >= 0) {
                    sms = smsHistory.sms;
                    var messageIndex = sms[index].messages.findIndex(x => x.date == action.payload.messages[0].date && x.message == action.payload.messages[0].message && x.type == action.payload.messages[0].type)
                    if (messageIndex >= 0)
                        sms[index].messages[messageIndex] = action.payload.messages[0]

                    //   // console.log(sms, 'hello messages111')

                    smsHistory.sms = sms
                    smsHistory.sms[index].date = action.payload.date;
                    state[smsHistoryIndex] = smsHistory
                }
            }
            // console.log(state, 'hello sms marksent')

            result = JSON.parse(JSON.stringify(state))
            return result;

        case ACTIONS.SMS_MARK_CLEAR:
            return []

        default:
            // console.log(state, 'hello - default')
            try {
                smsHistory = state.find(x => x.owner == action.owner)
                smsHistoryIndex = state.findIndex(x => x.owner == action.owner)

            } catch (error) {
                // console.log(error, 'hello -erro')
                return state
            }
            if (smsHistory) {

                var smsCopy = JSON.parse(JSON.stringify(smsHistory.sms))
                sms = smsHistory.sms
                // console.log(sms, 'hello -sms')
                for (let index = 0; index < smsCopy.length; index++) {
                    const element = smsCopy[index];
                    var lastMessage = element.messages[element.messages.length - 1]
                    // console.log(element, '-------messages')
                    // var a = getCurrentTime(store);
                    var milliseconds = getCurrentTime(store).diff(getConvertedTime(lastMessage.date).tz(process.env.REACT_APP_REGION), 'milliseconds', true)
                    // console.log(milliseconds, 'Hello - mill')
                    // console.log(CALL_MESSAGE_RULE.EXPIRATION, 'Hello - mill')
                    if (milliseconds >= CALL_MESSAGE_RULE.EXPIRATION) {
                        var smsIndex = sms.findIndex(x => x.peerId == element.peerId)
                        if (smsIndex && smsIndex > -1) {
                            sms.splice(smsIndex, 1)
                        }
                    }
                }

                smsHistory.sms = sms

                state[smsHistoryIndex] = smsHistory
                result = JSON.parse(JSON.stringify(state))
                return result;
            }
            else return state

    }
}

export default smsReducer;