import { ACTIONS } from '../utils/actions';

const welcomeMessageReducer = (state = { isRead: false, isShow: false }, action) => {
    switch (action.type) {
        case ACTIONS.WELCOME_MESSAGE:
            return action.payload;
        default: return state;
    }
}

export default welcomeMessageReducer;