import { ACTIONS } from '../utils/actions';

const incomingCallReducer = (state = null, action) => {
    switch (action.type) {
        case ACTIONS.INCOMING_CALL:
            return action.payload;
        default: return state;
    }
}

export default incomingCallReducer;