import { ACTIONS } from '../utils/actions';

const receivedMessageReducer = (state = {hasMessage : false, name : ""}, action) => {
    switch (action.type) {
        case ACTIONS.RECEIVED_MESSAGE:
            return action.payload
        default: return state;
    }
}

export default receivedMessageReducer;