import { combineReducers } from "redux";
import authReducer from "./authReducer";
import favoriteReducer from "./favoriteReducer";
import selectedGenderReducer from "./selectedGenderReducer";
import selectedReducer from "./selectedReducer";
import disclaimerReducer from "./disclaimerReducer";
import messageReducer from "./messageReducer";
import nearByReducer from "./nearByReducer";
import selectedMessageReducer from "./selectedMessageReducer";
import welcomeMessageReducer from "./welcomeMessageReducer";
import userAdsReducer from "./userAdsReducer";
import selectUserAdReducer from "./selectUserAdReducer";
import currentUserReducer from "./currentUserReducer";
import sessionReducer from "./sessionReducer";
import agoraReducer from "./agoraReducer";
import smsReducer from "./smsReducer";
import callReducer from "./callReducer";
import incomingCallReducer from "./incomingCallReducer";
import progressReducer from "./progressReducer";
import callHistoryReducer from "./callHistoryReducer";
import receivedMessageReducer from "./receivedMessageReducer";
import topUpReducer from "./topUpReducer";
import adListReducer from "./adListReducer";
import {  getOwner, isJsonObjectEmpty } from "../utils/common";
import {ACTIONS} from '../utils/actions';
import localStorage from "redux-persist/es/storage";
import balanceReducer from "./balanceReducer";
import addressReducer from "./addressReducer";
import adListFilterReducer from "./adListFilterReducer";
import messageAlertReducer from "./messageAlertReducer";
import changeLocationReducer from "./changeLocationReducer";
import blacklistReducer from "./blacklistReducer";
import deviceInfoReducer from "./deviceInfoReducer";
import blockAlertReducer from "./blockAlertReducer";
import topBarReducer from "./topBarReducer";
import settingReducer from "./settingReducer";
import agoraStatusReducer from "./agoraStatusReducer";
import tutorialReducer from "./tutorialReducer";
import locationPermissionReducer from "./locationPermissionReducer";
import adListStatusReducer from "./adListStatusReducer";
import verifiedReducer from "./verifiedReducer";
import adListLocationReducer from "./adListLocationReducer";
import addressDetailsReducer from "./addressDetailsReducer";
import balanceAlertReducer from "./balanceAlertReducer";
import notificationReducer from "./notificationReducer";
import genderModalReducer from "./genderModalReducer";
import requestReducer from "./requestReducer";
import mapReducer from "./mapReducer";
import systemNotificationReducer from "./systemNotificationReducer";
import selectedNotifReducer from "./selectedNotifReducer";
import resultBlockReducer from "./resultBlockReducer";
import buyerInfoReducer from "./buyerInfoReducer";
import serviceSettingReducer from "./serviceSettingReducer";
import timeDiscrepancyReducer from "./timeDiscrepancyReducer";
import serviceWorkerReducer from "./serviceWorkerReducer";
import backgroundFetchReducer from "./backgroundFetchReducer";
import postAdTutorialReducer from "./postAdTutorialReducer";
import contactsReducer from "./contactsReducer";
import addToHomeReducer from "./addToHomeReducer";
import signUpReducer from "./signUpReducer";
import sellerVisitAdReducer from "./sellerVisitAdReducer";
import sellerTutorialReducer from "./sellerTutorialReducer";
import oldSmsReducer from "./oldSmsReducer";
import oldCallHistoryReducer from "./oldCallHistoryReducer";
import sellerReplyReducer from "./sellerReplyReducer";
import chatMessageReducer from "./chatMessageReducer";
import addProfileImagePromptReducer from "./addProfileImagePromptReducer";
import twilioReducer from "./twilioReducer";

const reducers = combineReducers({
  addProfileImagePromptReducer: addProfileImagePromptReducer,
  addressReducer: addressReducer,
  addressDetailsReducer: addressDetailsReducer,
  addToHomeReducer: addToHomeReducer,
  adListFilterReducer: adListFilterReducer,
  adListLocationReducer: adListLocationReducer,
  adListReducer: adListReducer,
  adListStatusReducer: adListStatusReducer,
  agoraReducer: agoraReducer,
  agoraStatusReducer: agoraStatusReducer,
  authReducer: authReducer,
  backgroundFetchReducer: backgroundFetchReducer,
  balanceAlertReducer: balanceAlertReducer,
  balanceReducer: balanceReducer,
  blacklistReducer: blacklistReducer,
  blockAlertReducer: blockAlertReducer,
  buyerInfoReducer: buyerInfoReducer,
  callHistoryReducer: callHistoryReducer,
  oldCallHistoryReducer: oldCallHistoryReducer,
  callReducer: callReducer,
  changeLocationReducer: changeLocationReducer,
  chatMessageReducer: chatMessageReducer,
  contactsReducer: contactsReducer,
  currentUserReducer: currentUserReducer,
  deviceInfoReducer: deviceInfoReducer,
  disclaimerReducer: disclaimerReducer,
  favoriteReducer: favoriteReducer,
  genderModalReducer: genderModalReducer,
  incomingCallReducer: incomingCallReducer,
  locationPermissionReducer: locationPermissionReducer,
  mapReducer: mapReducer,
  messageAlertReducer: messageAlertReducer,
  messageReducer: messageReducer,
  nearByReducer: nearByReducer,
  notificationReducer: notificationReducer,
  progressReducer: progressReducer,
  postAdTutorialReducer: postAdTutorialReducer,
  receivedMessageReducer: receivedMessageReducer,
  requestReducer: requestReducer,
  resultBlockReducer: resultBlockReducer,
  selectedGenderReducer: selectedGenderReducer,
  selectedMessageReducer: selectedMessageReducer,
  selectedNotifReducer: selectedNotifReducer,
  selectedReducer: selectedReducer,
  sellerReplyReducer: sellerReplyReducer,
  sellerTutorialReducer: sellerTutorialReducer,
  selectUserAdReducer: selectUserAdReducer,
  sellerVisitAdReducer: sellerVisitAdReducer,
  sessionReducer: sessionReducer,
  serviceSettingReducer: serviceSettingReducer,
  serviceWorkerReducer: serviceWorkerReducer,
  settingReducer: settingReducer,
  signUpReducer: signUpReducer,
  smsReducer: smsReducer,
  oldSmsReducer: oldSmsReducer,
  systemNotificationReducer: systemNotificationReducer,
  timeDiscrepancyReducer: timeDiscrepancyReducer,
  topBarReducer: topBarReducer,
  topUpReducer: topUpReducer,
  tutorialReducer: tutorialReducer,
  twilioReducer : twilioReducer,
  userAdsReducer: userAdsReducer,
  verifiedReducer: verifiedReducer,
  welcomeMessageReducer: welcomeMessageReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state

  try {
    action.owner = getOwner(state);
  } catch (error) {
    // console.log(error, 'redux')
  }

  try {
    var addressReducer = state.addressReducer;
    var map = state.mapReducer;
    action.hasLocation =
      (addressReducer.longitude !== 0 && addressReducer.latitude !== 0) ||
      !isJsonObjectEmpty(map);
  } catch (error) {

    // console.log(error, 'redux')
  }
  try {

    if (action.type === ACTIONS.LOGOUT) {
      var tutorial = state.tutorialReducer;
      var sessionId = state.sessionReducer;
      // var smsReducer = state.smsReducer;
      var addressDetailsReducer = state.addressDetailsReducer;
      var welcomeMessageReducer = state.welcomeMessageReducer;
      var chatMessageReducer = state.chatMessageReducer;
      var sellerReplyReducer = state.sellerReplyReducer;
      var buyerInfo = state.buyerInfoReducer;
      var disclaimer = state.disclaimerReducer;
      var adTutorial = state.postAdTutorialReducer;
      var adToHome = state.addToHomeReducer;
      var serviceSetting = state.serviceSettingReducer;
      var signUp = state.signUpReducer;
      var sellerVisit = state.sellerVisitAdReducer;
      var favoriteReducer = state.favoriteReducer;
      localStorage.removeItem("persist:root");
      // storage.removeItem('persist:otherKey')
      state = undefined;
      state = {
        sessionReducer: sessionId,
        buyerInfoReducer: buyerInfo,
        tutorialReducer: tutorial,
        disclaimerReducer: disclaimer,
        postAdTutorialReducer: adTutorial,
        addToHomeReducer: adToHome,
        favoriteReducer: favoriteReducer,
        // smsReducer: smsReducer,
        addressReducer: addressReducer,
        addressDetailsReducer: addressDetailsReducer,
        welcomeMessageReducer: welcomeMessageReducer,
        chatMessageReducer: chatMessageReducer,
        sellerReplyReducer: sellerReplyReducer,
        serviceSettingReducer: serviceSetting,
        signUpReducer: signUp,
        sellerVisitAdReducer: sellerVisit,
        authReducer : ""
      };
      localStorage.setItem("persist:root", JSON.stringify(state))

      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);
    }
  } catch (error) {
    console.log(error, 'hello')
  }
  return reducers(state, action);
};

export default rootReducer;

// export default reducers;
