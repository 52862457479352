import { ACTIONS } from '../utils/actions';
import {uniqBy} from '@s-libs/micro-dash';
const userAdsReducer = (state = [], action) => {
    switch (action.type) {
        case ACTIONS.USER_ADS:
            var ads = [];
            // console.log(action.payload,'userads')
            // if (action.payload.length > 0)
            //     for (let index = 0; index < action.payload.length; index++) {
            //         const element = action.payload[index];
            //         ads.push[{ ...element, ...element.details }]
            //     }
            ads = uniqBy(action.payload, function (e) {
                return e.details.id;
            });

            return ads;
        default: return state;
    }
}

export default userAdsReducer;