import { ACTIONS } from '../utils/actions';

const backgroundFetchReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.BACKGROUND_FETCH:
            return action.payload;
        default: return state;
    }
}

export default backgroundFetchReducer;