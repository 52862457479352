

const MESSAGE_TYPE = Object.freeze({
    SENDER: "SENDER",
    RECEIVER: "RECEIVER",
});

const AGORA_TYPE = Object.freeze({
    MESSAGE: "rtm",
    CALL: "rtc",
});

const CALL_STATUS = Object.freeze({
    CHECKING_BROWSER: "CHECKING_BROWSER",
    ONGOING: "ONGOING",
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",
    NO_ANSWER: "NO_ANSWER",
    CANCELLED: "CANCELLED",
    CALL_ENDED: "CALL_ENDED",
    INITIALIZING: "INITIALIZING",
});

const CONNECTION_STATUS = Object.freeze({
    NOT_ESTABLISH: "NOT_ESTABLISH",
    ESTABLISH: "ESTABLISH",
});



const BALANCE_TYPE = Object.freeze({
    LOW_BALANCE: {
      type: "LOW BALANCE",
      message:
        "You are running low on top up, otherwise you will not be able to respond to buyers",
    },
    INSUFFICIENT_BALANCE: {
      type: "INSUFFICIENT BALANCE",
      message:
        "You are receiving a call, however you have insufficient credit to accept this call. Please top up your account",
    },
  });
  
  const CALL_MESSAGE_RULE = Object.freeze({
    EXPIRATION: 5400000, // in millisecconds
    // EXPIRATION: 60000, // in millisecconds
    CALL_ALERT: 30, // 30 seconds before the ending in seconds
    CALL_DUE: 300, // in seconds
    MESSAGE_LIMIT: 20,
    MESSAGE_LIMIT_ALERT: 2,
    BLOCK_DURATION: 7, // days
  });
  
  const MESSAGE_STATUS = Object.freeze({
    BLOCK: "BLOCK",
    LEAVE_CALL: "LEAVE_CALL",
    MESSAGE: "MESSAGE",
    TWILIO: "TWILIO_MESSAGE",
    TEXT: "TEXT"
  });
  

export {
    CONNECTION_STATUS,
    AGORA_TYPE,
    CALL_STATUS,
    MESSAGE_STATUS,
    MESSAGE_TYPE,
    CALL_MESSAGE_RULE,
    BALANCE_TYPE
}