
import { ACTIONS } from '../utils/actions';
import { PROGRESS_TYPE } from '../utils/progressType';
const progressReducer = (state = { isProgress: false, message: "", type: PROGRESS_TYPE.PROGRESS }, action) => {
    switch (action.type) {
        case ACTIONS.PROGRESS:
            return { ...state, ...action.payload };
        default: return state;
    }
}

export default progressReducer;