import { ACTIONS } from '../utils/actions';

const sellerReplyReducer = (state = { call : false , chat : false }, action) => {
    switch (action.type) {
        case ACTIONS.SELLER_REPLY:
            return { ...state, ...action.payload };
        default: return state;
    }
}

export default sellerReplyReducer;