import { ACTIONS } from '../utils/actions';

const deviceInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.DEVICE_INFO:
            return action.payload;
        default: return state;
    }
}

export default deviceInfoReducer;