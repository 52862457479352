
const ACTIONS = Object.freeze({
    SELECTED: "SELECTED",
    BACKGROUND_FETCH: "BACKGROUND_FETCH",
    FAVOURITE_ADD: "FAVOURITE_ADD",
    FAVOURITE_REMOVE: "FAVOURITE_REMOVE",
    FAVOURITE_REMOVE_ALL: "FAVOURITE_REMOVE_ALL",
    FAVORITE_UPDATE_STATUS: "FAVORITE_UPDATE_STATUS",
    NOTIFICATION: "NOTIFICATION",
    LOCATION_ADD: "LOCATION_ADD",
    LOCATION_REMOVE: "LOCATION_REMOVE",
    CONTACT: "CONTACT",
    LOCATION_REMOVE_ALL: "LOCATION_REMOVE_ALL",
    SPAM_ADD: "SPAM_ADD",
    SPAM_REMOVE: "SPAM_REMOVE",
    AUTH: "AUTH",
    GENDER_MODAL: "GENDER_MODAL",
    DISCLAIMER: "DISCLAIMER",
    MESSAGE: "MESSAGE",
    MAP: "MAP",
    NOTICE: "NOTICE",
    SELECT_GENDER: "SELECT_GENDER",
    NEARBY: "NEARBY",
    SELECT_MESSAGE: "SELECT_MESSAGE",
    WELCOME_MESSAGE: "WELCOME_MESSAGE",
    CHAT_MESSAGE: "CHAT_MESSAGE",
    SELLER_VISIT_AD: "SELLER_VISIT_AD",
    USER_ADS: "USER_ADS",
    AD_LIST: "AD_LIST",
    AD_LIST_UPDATE_STATUS: "AD_LIST_UPDATE_STATUS",
    AD_LIST_UPDATE_DISTANCE: "AD_LIST_UPDATE_DISTANCE",
    SELECT_USER_AD: "SELECT_USER_AD",
    DEVICE_INFO: "DEVICE_INFO",
    CURRENT_USER: "CURRENT_USER",
    SESSION: "SESSION",
    BALANCE: "BALANCE",
    BALANCE_ALERT: "BALANCE_ALERT",
    PROGRESS: "PROGRESS",
    SELLER_REPLY: "SELLER_REPLY",
    VERIFY: "VERIFY",
    SYSTEM_NOTIFICATION: "SYSTEM_NOTIFICATION",
    READ_SYSTEM_NOTIF: "READ_SYSTEM_NOTIF",
    AGORA: "AGORA", TWILIO: "TWILIO",
    AGORA_STATUS: "AGORA_STATUS",
    SMS: "SMS",
    SMS_OLD: "SMS_OLD",
    SERVICE_SETTING: "SERVICE_SETTING",
    ADD_TO_HOME: "ADD_TO_HOME",
    SMS_REMOVE: "SMS_REMOVE",
    SMS_REMOVE_OLD: "SMS_REMOVE_OLD",
    SMS_MARK_READ: "SMS_MARK_READ",
    SMS_MARK_SENT: "SMS_MARK_SENT",
    SMS_UPDATE_DISTANCE: "SMS_UPDATE_DISTANCE",
    SMS_MARK_CLEAR: "SMS_MARK_CLEAR",
    SMS_MARK_CLEAR_OLD: "SMS_MARK_CLEAR_OLD",
    CALL_HISTORY: "CALL_HISTORY",
    CALL_HISTORY_REMOVE: "CALL_HISTORY_REMOVE",
    CALL_HISTORY_OLD: "CALL_HISTORY_OLD",
    CALL_HISTORY_REMOVE_OLD: "CALL_HISTORY_REMOVE_OLD",
    CALL_MARK_READ: "CALL_MARK_READ",
    CALL: "CALL",
    INCOMING_CALL: "INCOMING_CALL",
    REQUEST: "REQUEST",
    RECEIVED_MESSAGE: "RECEIVED_MESSAGE",
    BLACKLIST: "BLACKLIST",
    TOP_UP: "TOP_UP",
    LOGOUT: "USER_LOGGED_OUT",
    ADDRESS: "ADDRESS",
    FILTER_ADLIST: "AD_LISTFILTER",
    ADLIST_STATUS: "ADLIST_STATUS",
    ADDRESS_DETAILS: "ADDRESS_DETAILS",
    CHANGE_LOCATION: "CHANGE_LOCATION",
    MESSAGE_ALERT: "MESSAGE_ALERT",
    BLOCK_ALERT: "BLOCK_ALERT",
    TOP_BAR: "TOP_BAR",
    SETTING: "SETTING",
    RESULT_BLOCK: "RESULT_BLOCK",
    TUTORIAL: "TUTORIAL",
    SELLER_TUTORIAL: "SELLER_TUTORIAL",
    AD_TUTORIAL: "AD_TUTORIAL",
    LOCATION_PERMISSION: "LOCATION_PERMISSION",
    SELECTED_NOTIF: "SELECTED_NOTIF",
    BUYER_INFO: "BUYER_INFO",
    CURRENT_TIME: "CURRENT_TIME",
    SW_UPDATE: "SW_UPDATE",
    SW_INIT: "SW_INIT",
    SIGN_UP: "SIGN_UP",
    ADD_PROFILE_IMAGE_PROMPT: "ADD_PROFILE_IMAGE_PROMPT",
});

export {
    ACTIONS
}