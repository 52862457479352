
import { ACTIONS } from '../utils/actions';


const oldSmsReducer = (state = [], action) => {

    var newMessages = []
    var newSms = {}
    var smsHistory, smsHistoryIndex, index, sms, result

    switch (action.type) {
        case ACTIONS.SMS_OLD:
            action.payload.date = action.payload.messages[0].date
            if (!state || state.length == 0) {
                newMessages.push(action.payload)
                newSms = {
                    owner: action.owner,
                    sms: newMessages
                }
                state.push(newSms)

            }
            else {
                smsHistory = state.find(x => x.owner == action.owner)
                smsHistoryIndex = state.findIndex(x => x.owner == action.owner)

                if (smsHistory) {
                    sms = smsHistory.sms;
                    index = smsHistory.sms.findIndex(x => x.peerId == action.payload.peerId && x.adId == action.payload.adId)
                    if (index >= 0) {
                        sms[index].distance = action.payload.distance
                        sms[index].isRead = action.payload.isRead
                        sms[index].timeSend = action.payload.timeSend
                        sms[index].messages.push(...action.payload.messages)

                    } else {
                        sms.push(action.payload)
                    }
                    smsHistory.sms = sms;
                    state[smsHistoryIndex] = smsHistory
                }
                else {
                    newMessages.push(action.payload)
                    newSms = {
                        owner: action.owner,
                        sms: newMessages
                    }
                    state.push(newSms)
                }
            }

            // console.log(state, 'hello sms action')

            result = JSON.parse(JSON.stringify(state))

            return result;
        case ACTIONS.SMS_REMOVE_OLD:
            if (state.length == 0) return state;
            smsHistory = state.find(x => x.owner == action.owner)
            smsHistoryIndex = state.findIndex(x => x.owner == action.owner)


            index = smsHistory.sms.findIndex(x => x.peerId == action.payload.peerId)
            if (index >= 0) {
                sms = smsHistory.sms;
                var messagesLastIndex = sms[index].messages.length - 1;
                var payloadLastMessage = action.payload.messages.length - 1
                if (sms[index].messages[messagesLastIndex].date === action.payload.messages[payloadLastMessage].date) {
                    sms.splice(index, 1)
                }

                smsHistory.sms = sms
                state[smsHistoryIndex] = smsHistory
            }

            // console.log(state, 'hello sms remove')

            result = JSON.parse(JSON.stringify(state))
            return result;
        case ACTIONS.SMS_MARK_CLEAR_OLD:
            return []

        default:
            return state

    }
}

export default oldSmsReducer;