import { uniqBy } from '@s-libs/micro-dash';
import orderBy from 'lodash.orderby';
import { ACTIONS} from '../utils/actions';

const adListReducer = (state = [], action) => {
    var ads = []
    switch (action.type) {
        case ACTIONS.AD_LIST:
            ads = uniqBy(action.payload, function (e) {
                return e.id;
            });
            ads = ads.filter(x => !x.isDeleted)

            // ads = ads.filter(function (x) {

            //     var loginTime = getConvertedTime(getConvertedTimeFromBackend(x.loginTime))
            //     if (x.logoutTime) {
            //         if (getConvertedTime(getConvertedTimeFromBackend(x.logoutTime)).isAfter(loginTime)) {
            //             loginTime = getConvertedTime(getConvertedTimeFromBackend(x.logoutTime))
            //         }
            //     }
            //     return getCurrentTime().diff(loginTime, 'days') <= 7
            // })
            if (action.hasLocation) {
                ads = orderBy(ads, [item => parseFloat(item.distance != "unknown" ? item.distance : 0)], ['asc'])
            }
            return ads;
        case ACTIONS.AD_LIST_UPDATE_STATUS:
            var edited = action.payload
            for (let index = 0; index < state.length; index++) {
                const element = state[index];
                var onlineStatus = edited.find(el => el.id == element.id)
                if (onlineStatus) {
                    element.isOnline = onlineStatus.isOnline
                }
                ads.push(element)
            }
            return ads
        case ACTIONS.AD_LIST_UPDATE_DISTANCE:
            var stateIndex = state.findIndex(el => el.id == action.payload.id)
            if (stateIndex >= 0) {
                state[stateIndex].distance = action.payload.distance
            }
            return state
        default: return state;
    }
}

export default adListReducer;