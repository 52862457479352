import { ACTIONS } from '../utils/actions';

const defaultState = {
    isAllowedCall: false, isAllowedChat: false,
    isAllowedCountry: false,
    isAllowedCountryModalOpen: false,
    isCreditEnable: false,
    isAllowedModal : true,
    backgroundFetchDate : '',
    isOpenBanWordsPrompt : false,
    openBanWordsSection : "",
    isUpdated : false,
    banWords : []
}
const serviceSettingReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ACTIONS.SERVICE_SETTING:
            return { ...state, ...action.payload };
        default: return state;
    }
}

export default serviceSettingReducer;