
const GENDERS = Object.freeze({
    FEMALE: {
        NAME: "Female",
        VALUE: 0,
    },
    MALE: {
        NAME: "Male",
        VALUE: 1,
    },
    TRANSGENDER: {
        NAME: "Transgender",
        VALUE: 2,
    },
    ALL: {
        NAME: "All",
        VALUE: null,
    },
});

const getGenderIndex = (gender) => {
    if (gender == "Female") return 0;
    if (gender == "Male") return 1;
    return 2;
};

const getGenderByIndex = (gender) => {
    if (gender == 0) return "Female";
    if (gender == 0) return "Male";
    return "Trans";
};

export {
    GENDERS,
    getGenderByIndex,
    getGenderIndex
}