import differenceWith from "lodash.differencewith";
import { sample, isEqual } from "@s-libs/micro-dash";
import {
  bannedWordContains,
  bannedWordCustomContains,
  bannedWords,
} from "../data/data";
import { names } from "../data/names";

import chiccaLogo from "../assets/images/chicca-icon-hires.webp";
// import store from '../store'
import moment from "moment-timezone";
import orderBy from "lodash.orderby";

const COMMON_STRINGS = Object.freeze({
  NEAR_BY_HEADER: "Explore",
  NEAR_BY: "Who's Nearby Now",
  CHICCA: "ChiccaChicca",
  CHICCA_CHICCA: "ChiccaChicca",
  POST_AD: "Post an Ad",
  VERIFICATION: "A verification code will be sent to this mobile number",
  CREATE_PROFILE: "Create your profile",
  NEW: "NEW",
  ONLINE: "Online",
  INCOGNITO: "Incognito",
  ONLINE_NOW: "Online Now",
  YOURE_ONLINE: "You are now online",
  OFFLINE: "Offline",
  FILTER: "Filter",
  GENDER: "Gender",
  AGE: "Age",
  PRICE: "Price",
  ETHNICITY: "Ethnicity",

  SELECT_TYPE: "Filter Escorts",

  ADS_WITH_VIDEO: "Ads with Videos",
  NEW_LISTING: "New Listings",
  ESCORT_TYPE: "Escort Type",
  TYPE_OF_AD: "Type of Ad",
  LANGUAGE: "Language",
  SERVICES: "Services",
  SERVICES_FOR: "Services For",
  SERVICES_FOR_WHO: "Services for who",
  MY_SERVICES_FOR: "My Services is for",
  RATES: "Rates",
  INCALL: "Incall",
  OUTCALL: "Outcall",
  DECSRIPTION: "Description",
  VIEW_FAVORITE: "View Favorites",
  BLOCK: "Block",
  REPORT_AD: "Report this Profile",
  CONCERN: "Concern for Escort",
  SPAM: "Spam",
  BLOCK_QUESTION: "Do you want to end this [type] and block this user?",
  BLOCK_QUESTION_1: "Are you sure you want to block this [type]?",
  DELETE_CONFIRMATION: "Are you sure you want to delete this profile?",
  DELETE_CONFIRMATION_LAST_AD:
    "It's the only profile you have set up, so if you delete it, you won't appear on the site until you create a new one.",
  YES: "Yes",
  NO: "No",
  OKAY: "Okay",
  CANCEL: "Cancel",
  CLOSE: "Close",
  CREDIT_ACCOUNT: "Credit your account",
  HELP: "Help",
  SPAM_QUESTION: "Do you want to mark this profile as spam?",
  REPORT_QUESTION: "Describe why you want to report this profile below",

  REPORT_DESCRIPTION:
    "Please provide the name, phone number or link of the ad that you're referring to.",
  REPORT_DESCRIPTION2:
    "If you provide no name, phone number or link, we won't have access to sufficient information to process your request",
  PLATFORM_FEEDBACK: "What's your feature request or feedback?",
  SEND_CONCERN: "Describe your concerns below",
  SEND_CONCERN_DESCRIPTION:
    "Please provide the name, phone number or link of the ad that you're referring to.",
  SEND_CONCERN_DESCRIPTION2:
    "If you provide no name, phone number or link, we won't have access to sufficient information to process your request",
  TYPE_MESSAGE: "Type your message..",
  FAVORITE: "Favourites",
  NO_AD_AVAILABLE: "No Ads available, try changing the filter.",
  LOADING_ADS: "Ads for your gender selection loading now.",
  NO_AD_AVAILABLE_NEARBY: "No Ads available nearby, try changing the location.",
  NO_FAVORITE: "No Favourites yet",
  NO_FAVORITE_DETAIL: "Tap the star on a profile to save them here",
  CLICK_REFRESH: "Click to refresh",
  PROCEED_WITHOUT_LOCATION: "Proceed without location",
  CALL_ACCEPTED: "Call Accepted",
  CALL_REJECTED: "Call Rejected",
  CALLING: "Calling [name]...",
  NO_ANSWER: "No Answer",
  CANCELLED: "Call Cancelled",
  OUTGOING_CALL: "Outgoing Call",
  INCOMING_CALL: "Incoming Call",
  MUTE: "Mute",
  SPEAKER: "Speaker",
  SEARCH_MESSAGE: "Search Messages",
  SEARCH_CALL: "Search Calls",
  MESSAGE_RECEIVED: "Message received",
  CALL_RECEIVED: "Call received",
  ENTER_EMAIL: "Enter Email",
  EMAIL_ADDRESS: "Email address",
  RE_ENTER_EMAIL: "Re-enter email address",
  ENTER_PASSWORD: "Enter Password",
  RE_ENTER_PASSWORD: "Re-enter Password",
  ENTER_YOUR_EMAIL: "Enter your email",
  ENTER_YOUR_NEW_EMAIL: "Enter your new email",
  RE_ENTER_YOUR_NEW_EMAIL: "Re-enter your new email",
  THANK_YOU: "Thank you for using ChiccaChicca.",

  USERNAME: "Username",
  ENTER_YOUR_MOBILE_NUMBER: "Please enter your mobile number here",
  ENTER_YOUR_NEW_MOBILE_NUMBER: "Please enter your new phone number",
  PASSWORD: "Password",
  LOGIN: "Login",
  ESCORT_SIGN_UP: "Escort Sign Up",
  FORGOT_PASSWORD: "Forgot your Password?",
  REGISTER_ACCOUNT: "Register for a FREE account",
  REGISTER_NOW: "Sign Up Now",
  NEXT: "Next",
  PUBLISH_AD: "Publish your profile",
  PROFILE_NAME: "Profile name",
  PROFILE_HEADLINE: "Headline - displayed on your ad",
  PROFILE_HEADLINE_DESCRIPTION: "Show off a name, slogan or specialty",
  PROFILE_DESCRIPTION: "Profile description",
  INCALL_ADDRESS: "Incall Address",
  INCALL_ADDRESS_DETAILS:
    "This is not displayed publicly. This is for when you decide to meet the client and you can send your address by a quick press. This is specific to this profile and can be changed any time in My Accounts and editing the profile.",
  AD_HEADLINE: "Ad headline (max 15 characters)",
  YOU_ARE: "You are",
  ADD_PICTURE: "Add Pictures",
  CARD_HOLDER_NAME: "Card Holder Name",
  CARD_NUMBER: "Card Number",
  AMOUNT: "Enter Amount",
  REDIRECT_PAYMENT: "You will be redirected to a secured payment page",
  EXPIRES_ON: "Expires on",
  SECURITY_CODE: "Security Code",
  SELECT_PREFERRED_GENDER: "Please select preferred gender:",
  AGREE_PROCEED: "Agree and Proceed",
  LEAVE_WEBSITE: "Leave Website",
  CHAT_HINT: "Say something..",
  ENTER_OTP: "Enter the verification code sent to your email",
  ENTER_OTP_SMS: "Enter verification code sent by SMS",
  SUBMIT: "Submit",
  RESEND_CODE: "Resend code",
  CREATE_ACCOUNT: "Create an Account",
  REGISTER_USER_AGREEMENT:
    "By clicking register now, I agree to ChiccaChicca's [terms], [guidelines] and accept the [policy]",
  POSTING_AGREEMENT:
    "I agree to ChiccaChicca's [terms] Posting Guidelines and accept the [policy]",
  TERMS_URL: "/terms",
  POLICY_URL: "/policy",
  POSTING_GUIDELINES: "/guidelines",
  DISCLAIMER_URL: "google.com",
  PROCEED: "Proceed",
  NO_ADS: "You haven't posted an ad yet",
  WELCOM_CHICCA: "Welcome to ChiccaChicca!",
  AUTO_MESSAGE: "Hi [name], are you free to meet?",
  AUTO_MESSAGE_SELLER: "Hi [name], you called?",
  BROWSE_PROFILE: "Browse other profiles",
  GO_BACK_BROWSING: "Go back to browsing",
  CONTINUE_BROWSING: "Continue browsing",
  SEND_MESSAGE: "Send a message",
  WAIT_USER_TO_RESPOND: "Wait for [name] to respond?",
  CONTINUE_CHATTING: "Continue chatting",
  IGNORE_MESSAGE: "Ignore message",
  TOP_UP_WALLET: "Top Up Wallet",
  LEAVE_CALL_MESSAGE:
    "Leaving this page will end the call. Are you sure you want to leave?",
  SAVE: "Save",
  CALL_ALERT: "The call is about to reach [time] mins.",
  CONTINUE: "Continue",
  DO_NOT_SHOW_NOTIFICATION: "Do not show this message again",
  MAXIMUM_CALL_EXCEEDED: "Maximum call duration exceeded",

  MESSAGE_NOTIF: "[message] messages remaining (both users).",
  MESSAGE_ALERT: "Maximum messages exceeded",
  BLOCK_ALERT1: "[name] has blocked you.",

  BLOCK_STRIKE1: "You have [strike] strikes left before you are banned",
  BLOCK_STRIKE2: "You have received 3 strikes. You are no longer able to use the service.",
  BLOCK_ALERT2: 'You will not be charged for this [type]',
  BLOCK_ALERT3: 'You are currently blocked by this user',
  MAXIMUM_CALL_MESSAGE: "Maximum call duration : [time] mins.",
  REMAINING_CALL_DURATION: "Remaining call duration : ",
  NOTE: "Note",
  MESSAGE_DETAIL: "[limit] messages per chat maximum (total messages)",
  LOCATION_PERMISSION: "It seems that the location services for this browser is not turned on. To maximize functionality, you can set the location of your device and reload the page afterwards.",
  ENTER_CURRENT_PASSWORD: "Enter Current Password",
  ENTER_NEW_PASSWORD: "Enter New Password",
  RE_ENTER_NEW_PASSWORD: "Re-Enter New Password",
  CHANGE_PASSWORD: "Change Password",
  CHECK_EMAIL: "Check your email",
  CHECK_EMAIL_DESCRIPTION: "We have sent verification codes to your email.",
  CHECK_EMAIL_DESCRIPTION2: "Verification email missing? Check your junk items.",
  CHECK_MOBILE_DESCRIPTION: "We have sent verification codes to your mobile number.",
  CHECK_MOBILE_DESCRIPTION2: "Verification code is not delivered? Try resending it.",
  EMAIL_NEED_VERIFICATION: "Email is not yet verified",
  CHECK_MOBILE: "Check your phone",
  MOBILE_NEED_VERIFICATION: "Mobile Number is not yet verified",
  VERIFY_EMAIL: "Verify Email",
  VERIFY_ACCOUNT: "Verify Account",
  SELECT_OPTION: "Select option",
  ADS_ALREADY_DELETED: "This ad is already deleted",
  USER_NO_AUDIO: "The user's device is not supported to make this call.",
  UNSUPPORTED_BROWSER: "Your browser is not supported to make this call. Please try using other browser.",
  OLD_BROWSER: "Please update your browser to make this call",
  DEFAULT_ERROR_CALL: "Can not initiate call.",
  PUBLISH_ERROR_CALL: "Please make sure that you enable your mic permission to be able to make this call.",

  CHECKING_BROWSER: "Please wait while we check your browser compatibility",
  SIGN_UP: "Sign Up",
  SIGN_ME_UP: "Sign me up",
  ADD_PROFILE_IMAGE_PROMPT1:
    "Photos are the best way for getting clients' attention!",
  ADD_PROFILE_IMAGE_PROMPT2:
    "But you don't seem to have any photos on your profile right now.",
  ADD_PROFILE_IMAGE_PROMPT3: "Let's change that",
});



function changePropertyValue(id, propertyToChange, valueToChange, array) {
  for (var i in array) {
    if (array[i].id == id) {
      array[i][propertyToChange] = valueToChange;
      break;
    }
  }
  return array;
}

function generalDate(date) {
  // var moment = require('moment');
  date = getConvertedTime(date);
  var dateNow = moment();
  if (dateNow.diff(date, "days") == 0) {
    return getConvertedTime(date).format("HH:mm");
  } else {
    return getConvertedTime(date).format("DD-MM-YYYY HH:mm");
  }
}

function getFormattedDate(date, format = "DD-MM-YYYY HH:mm") {
  return moment(date)
    .tz(getDeviceTimeZone())
    .format(format);
}

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


function formDataToJsObject(formData) {
  var object = {};
  formData.forEach((value, key) => {
    // Reflect.has in favor of: object.hasOwnProperty(key)
    if (!Reflect.has(object, key)) {
      object[key] = value;
      return;
    }
    if (!Array.isArray(object[key])) {
      object[key] = [object[key]];
    }
    object[key].push(value);
  });
  var json = JSON.stringify(object);
  return json;
}

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
const getDataUrl = (a) => {
  return a.prefix + "" + a.data;
};
function jsObjectToFormData(obj) {
  // fd = form || new FormData();
  // let formKey;

  // for (var property in obj) {
  //     if (obj.hasOwnProperty(property)) {
  //         formKey = property;
  //         // if the property is an object, but not a File,
  //         // use recursivity.
  //         if (Array.isArray(obj[property])) {
  //             for (let index = 0; index < obj[property].length; index++) {
  //                 const element = obj[property][index];
  //                 if (typeof (element) === 'object') {
  //                     Object.keys(element).forEach(key => {
  //                         fd.append(formKey + '[' + index + ']' + '[' + key + ']', element[key])
  //                     });
  //                 } else if (typeof (element) === 'string') {
  //                     fd.append(formKey + '[' + index + ']', element)
  //                 }
  //             }
  //         } else {
  //             const element = obj[property];
  //             if (typeof (element) === 'object') {
  //                 Object.keys(element).forEach(key => {
  //                     fd.append(formKey + '[' + key + ']', element[key])
  //                 });
  //             } else
  //                 fd.append(formKey, obj[property]);
  //         }
  //     }
  // }

  // return fd;
  return obj;
}

function jsObjectToFormData1(obj, form, fd) {
  fd = form || new FormData();
  let formKey;

  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      formKey = property;
      // if the property is an object, but not a File,
      // use recursivity.
      if (Array.isArray(obj[property])) {
        for (let index = 0; index < obj[property].length; index++) {
          const element = obj[property][index];
          if (typeof element === "object") {
            Object.keys(element).forEach((key) => {
              fd.append(
                formKey + "[" + index + "]" + "[" + key + "]",
                element[key]
              );
            });
          } else if (typeof element === "string") {
            fd.append(formKey + "[" + index + "]", element);
          }
        }
      } else {
        const element = obj[property];
        if (typeof element === "object") {
          Object.keys(element).forEach((key) => {
            fd.append(formKey + "[" + key + "]", element[key]);
          });
        } else fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
}

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function stringToArray(string, separator) {
  if (Array.isArray(string)) {
    string = string.toString();
  }
  string = string.replace(" ", "");
  var array = string.split(separator);
  return array;
}

function cleanJsonObject(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

function isJsonObjectEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }

  return true;
}

function getSafeImage(image) {
  try {
    if (typeof image === "object") return image.data;
    else if (typeof image === "string") return image ? image : chiccaLogo;
    else return chiccaLogo;
  } catch (error) {
    return chiccaLogo;
  }
}

function getMiles(i) {
  return i * 0.000621371192;
}
function getKilometers(i) {
  // return i*1609.344;
  return i / 1000;
}
function getKmToM(i) {
  // return i*1609.344;
  return i * 1000;
}

function getDisplayDistance(distance) {
  var distanceInKm = Math.floor(getKilometers(distance));
  if (distanceInKm < 1) {
    return roundUpNearest(distance, 1, false) + "m away";
  } else if (distanceInKm < 3) {
    var dis = parseFloat(roundUpNearest(distance, 100)) / 1000;
    return dis + "km away";
  } else if (distanceInKm < 5) {
    var dis = parseFloat(roundUpNearest(distance, 500)) / 1000;
    return dis + "km away";
  } else {
    return Math.round(distanceInKm) + "km away";
  }
}

function roundUpNearest(num, nearest = 10, isCeil = false) {
  if (isCeil) return Math.ceil(num / nearest) * nearest;
  return Math.round(num / nearest) * nearest;
}

function calculateDistance(lattitude1, longittude1, lattitude2, longittude2) {
  if (
    (lattitude1 == 0 && longittude1 == 0) ||
    (lattitude2 == 0 && longittude2 == 0)
  )
    return 0;
  // console.log(lattitude1, longittude1, lattitude2, longittude2,'distance')
  const toRadian = (n) => (n * Math.PI) / 180;

  let lat2 = lattitude2;
  let lon2 = longittude2;
  let lat1 = lattitude1;
  let lon1 = longittude1;

  let R = 6378.137; // km
  let x1 = lat2 - lat1;
  let dLat = toRadian(x1);
  let x2 = lon2 - lon1;
  let dLon = toRadian(x2);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadian(lat1)) *
    Math.cos(toRadian(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;

  d = parseFloat(d).toFixed(7);
  // console.log(d, 'distance')
  return getKmToM(d);

  // var radLat1 = lattitude1 * Math.PI / 180.0;
  // var radLat2 = lattitude2 * Math.PI / 180.0;
  // var a = radLat1 - radLat2;
  // var b = (longittude1 * Math.PI / 180.0) - (longittude2 * Math.PI / 180.0)
  // var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)))
  // s = s * R;
  // s = Math.round(s * 1000)

  // console.log("distance==?", s)
  // return getKmToM(s)
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

// function calculateDistance(lattitude1, longittude1, lattitude2, longittude2) {

//     var distance = 2 * 6378.137 * Math.asin(
//         Math.sqrt(
//             Math.pow(Math.sin(Math.PI * (longittude1 - longittude2) / 360), 2) + Math.cos(Math.PI * lattitude1 / 180) * Math.cos(lattitude2 * Math.PI / 180) * Math.pow(Math.sin(Math.PI * (lattitude1 - lattitude2) / 360), 2)
//         ))

//     var rad = function (x) {
//         return x * Math.PI / 180;
//     };

//     var R = 6378137; // Earth’s mean radius in meter
//     var dLat = rad(lattitude2 - lattitude1);
//     var dLong = rad(longittude2 - longittude1);
//     var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//         Math.cos(rad(lattitude1)) * Math.cos(rad(lattitude2)) *
//         Math.sin(dLong / 2) * Math.sin(dLong / 2);
//     var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//     var d = R * c;

//     console.log("distance==?", distance)
//     return getKmToM(distance)
//     // return d; // returns the distance in meter
// }

function maskNumbers(string) {
  return string.replace(/\d/g, "*");
}

function maskBanWords(string) {
  var words = string.split(" ");
  for (let index = 0; index < words.length; index++) {
    const element = words[index].toString().toLowerCase();
    // var isBanned = false;
    var bannedIndex = bannedWords.findIndex(
      (x) => x.toString().toLowerCase() == element.toString()
    );
    if (bannedIndex > -1) {
      words[index] = "****";
    } else {
      var chunkChar = "";
      for (let index = 0; index < element.length; index++) {
        const el = element.charAt(index);
        if (bannedWordCustomContains.includes(el)) chunkChar += "*";
        else chunkChar += el;
      }
      words[index] = chunkChar;
    }
  }

  return words.join(" ");
}

function checkBanWords(string) {
  var words = [];
  bannedWords.forEach((element) => {
    // var re = /\b(element)\b/g;
    // var result = re.test(String(string).toLowerCase());
    // string = string.toLowerCase()
    var regex = new RegExp("\\b" + element + "\\b");
    if (string.search(regex) >= 0) {
      words.push(element);
    }
  });

  bannedWordContains.forEach((element) => {
    if (string.includes(element)) words.push(element);
  });
  return words;
}

function checkBanNumbers(string) {
  var hasNumber = false;
  var words = string.split(" ");
  var numbersLength = string.replace(/[^0-9]/g, "").length;
  // for (let index = 0; index < words.length; index++) {
  //     var element = words[index];
  //     element = element.match(/\d+/g);

  //     if (element == null) continue;
  //     else element = element[0];

  // }

  return numbersLength > 10;
}

function checkCustomBanWords(string) {
  var words = [];
  bannedWords.forEach((element) => {
    // var re = /\b(element)\b/g;
    // var result = re.test(String(string).toLowerCase());
    // string = string
    var regex = new RegExp("\\b" + element + "\\b");
    if (string.search(regex) >= 0) {
      words.push(element);
    }
  });

  bannedWordCustomContains.forEach((element) => {
    if (string.includes(element)) words.push(element);
  });
  return words;
}

var isArrayEqual = function (x, y) {
  return differenceWith(x, y, isEqual).isEmpty();
};

var isObjectEqual = function (x, y) {
  return isEqual(x, y);
};

function generateName() {
  // id = str_second_half(id)
  var name = sample(names);
  return name;
}

// function str_first_half(str) {
//     str = str.toString()
//     var length = str.length
//     var half = Math.round(length / 2)
//     return str.slice(0, half);
// }

function str_second_half(str) {
  str = str.toString();
  var length = str.length;
  var half = Math.round(length / 2);
  var ret = str.slice(half - 1);
  return ret;
}

function getOwner(state) {
  try {
    var auth = state.authReducer;
    var session = state.sessionReducer;
    var owner = !isJsonObjectEmpty(auth) ? auth.userNumber : session.id;
    return owner;
  } catch (error) {
    return "";
  }
}

function getSms(sms, userNumber, adId, owner) {
  try {
    if (sms && sms.length > 0) {
      var smsOwner = sms.find((x) => x.owner == owner);
      if (smsOwner) {
        return smsOwner.sms.find(
          (x) => x.peerId === userNumber && x.adId == adId
        );
      }
      return {};
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
}

function getSmsByOwner(sms, owner) {
  try {
    if (sms) {
      var smsOwner = sms.find((x) => x.owner == owner);
      if (smsOwner) {
        var orderedSms = orderBy(smsOwner.sms, ["date"], ["desc"]);

        return orderedSms;
      }
      return {};
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
}

function strToBase64(data) {
  let stringToBase64 = Buffer.from(data.toString()).toString("base64");
  return stringToBase64;
}

function base64ToStr(data) {
  let buff = new Buffer(data, "base64");
  let text = buff.toString("ascii");
  return text;
}

function getFormattedAmount(n, hasDecimal = true) {
  const options = {
    minimumFractionDigits: hasDecimal ? 2 : 0,
    maximumFractionDigits: hasDecimal ? 2 : 0,
  };
  if (!n) {
    return "";
  }
  n = parseFloat(n).toFixed(2);
  var withCommas = Number(n).toLocaleString("en", options);
  return withCommas;
}

function generateHomeField(item) {
  var images = Object.assign([], item.details.img);
  var index = !images ? -1 : images.findIndex((x) => x.display);
  var displayImage = index == -1 ? getSafeImage(null) : images[index].data;
  delete item.details.item;

  var result = { ...item, ...item.details };
  result.img = displayImage;
  result.video = item.details.video;
  return result;
}
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function getCurrentTime(store) {
  try {
    var currentTime = store.getState().timeDiscrepancyReducer;
    return moment().add(currentTime, "milliseconds");
    // return moment()
  } catch (error) {
    return moment();
  }
}

function getConvertedTime(date) {
  try {
    if (typeof date == "object") {
      var newDate = date.utc();
      return newDate.tz(getDeviceTimeZone());
    }
    return moment(date).tz(getDeviceTimeZone());
    // return moment()
  } catch (error) {
    return moment();
  }
}

function getConvertedTimeFromBackend(date) {
  try {
    // london time
    return moment.tz(date, "Europe/London");
    // return moment()
  } catch (error) {
    return moment()
      .tz("Europe/London")
      .format("YYYY-MM-DD HH:mm:ss");
  }
}

function toUnicode(str) {
  return str
    .split("")
    .map(function (value, index, array) {
      var temp = value
        .charCodeAt(0)
        .toString(16)
        .toLowerCase();
      if (temp.length > 2) {
        return "\\u" + temp;
      }
      return value;
    })
    .join("");
}

function getDeviceTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

function toTitleCase(str) {
  if(!str) return str;
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function getLoginStatus(mins, hours, days) {
  var message = "";

  if (days > 0) {
    if (days >= 4) {
      message = "Last Online 1 day ago";
    } else if (days > 1) {
      message = "Last Online " + days + " days ago";
    } else {
      message = "Last Online 1 day ago";
    }
  } else if (hours > 0) {
    if (hours > 1) {
      message = "Last Online " + hours + " hours ago";
    } else {
      message = "Last Online 1 hour ago";
    }
  } else if (mins > 0) {
    message = "Last Online 1 hour ago";
  }
  return message;
}

function getFormattedHeadline(headline) {
  // const emojiRE = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
  // var emojis = headline.match(emojiRE);
  // if (emojis)
  //     for (let index = 0; index < emojis.length; index++) {
  //         const element = emojis[index];
  //         headline.replace(element, '11')
  //     }
  //     console.log(emojis)
  // return headline
  var chunk = Array.from(headline);
  const emojiRE = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
  var string = "";
  var isEmoji = false;
  for (let index = 0; index < chunk.length; index++) {
    const element = chunk[index];

    if (element.match(emojiRE)) {
      if (!isEmoji) string += '<span class="emoji">';
      string += "" + element;
      isEmoji = true;
    } else {
      if (isEmoji) {
        string += "</span";
        isEmoji = false;
      }
      string += element;
    }
  }
  if (!string) {
    string = "&nbsp;";
  }
  return string;
}

function isNumeric(str) {
  if (typeof str != "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

function transformServiceName(item) {
  // if(item == "A Levels"){
  //     return '"A" Levels';
  // }
  return item;
}
function ordinalSuffix(i) {
  var j = i % 10,
      k = i % 100;
  if (j == 1 && k != 11) {
      return i + "st";
  }
  if (j == 2 && k != 12) {
      return i + "nd";
  }
  if (j == 3 && k != 13) {
      return i + "rd";
  }
  return i + "th";
}
export {
  COMMON_STRINGS,
  isNumeric,
  checkBanNumbers,
  changePropertyValue,
  escapeRegExp,
  generalDate,
  makeid,
  jsObjectToFormData,
  jsObjectToFormData1,
  isJson,
  stringToArray,
  cleanJsonObject,
  isJsonObjectEmpty,
  isObjectEqual,
  getMiles,
  getKilometers,
  getDisplayDistance,
  maskNumbers,
  getFormattedDate,
  calculateDistance,
  checkBanWords,
  checkCustomBanWords,
  isArrayEqual,
  generateName,
  getOwner,
  getKmToM,
  getSms,
  getSmsByOwner,
  strToBase64,
  base64ToStr,
  getFormattedAmount,
  getSafeImage,
  generateHomeField,
  getCurrentTime,
  formDataToJsObject,
  onlyUnique,
  dataURLtoFile,
  getDataUrl,
  toUnicode,
  maskBanWords,
  toTitleCase,
  getDeviceTimeZone,
  getConvertedTime,
  getConvertedTimeFromBackend,
  getLoginStatus,
  getFormattedHeadline,
  transformServiceName,
  ordinalSuffix
}
