import { ACTIONS } from '../utils/actions';

const agoraStatusReducer = (state = '', action) => {
    switch (action.type) {
        case ACTIONS.AGORA_STATUS:
            return action.payload ;
        default: return state;
    }
}

export default agoraStatusReducer;