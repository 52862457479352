import { ACTIONS } from '../utils/actions';

const selectedReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.SELECTED:
            return action.payload;
        default: return state;
    }
}

export default selectedReducer;