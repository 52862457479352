import { ACTIONS } from '../utils/actions';
import { GENDERS } from '../utils/genders';
const selectedGenderReducer = (state = { hasSelected: false, selected: GENDERS.FEMALE }, action) => {
    switch (action.type) {
        case ACTIONS.SELECT_GENDER:
            return { ...state, ...action.payload };
        default: return state;
    }
}

export default selectedGenderReducer;