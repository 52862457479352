import { ACTIONS } from '../utils/actions';

const nearByReducer = (state = {isNearby: true, random : ''}, action) => {
    switch (action.type) {
        case ACTIONS.NEARBY:
            return action.payload;
        default: return state;
    }
}

export default nearByReducer;