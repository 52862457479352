import { ACTIONS } from '../utils/actions';

const twilioReducer = (state = {identity : "",token : ""}, action) => {
    switch (action.type) {
        case ACTIONS.TWILIO:
            return { ...state, ...action.payload };
        default: return state;
    }
}

export default twilioReducer;