import { ACTIONS } from '../utils/actions';

const tutorialReducer = (state = {isTutorial : false, isFirstTime : true}, action) => {
    switch (action.type) {
        case ACTIONS.TUTORIAL:
            return {...state, ...action.payload};
        default: return state;
    }
}

export default tutorialReducer;