import { ACTIONS } from '../utils/actions';

const sessionReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.SESSION:
            return action.payload;
        default: return state;
    }
}

export default sessionReducer;