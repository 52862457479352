import { ACTIONS } from '../utils/actions';
import { uniqBy } from '@s-libs/micro-dash';
import orderBy from 'lodash.orderby';

const initialState = {
    hasCall: false,
    history: [],
}

const oldCallHistoryReducer = (state = initialState, action) => {
    var history = state.history
    switch (action.type) {
        case ACTIONS.CALL_HISTORY_OLD:
            history = [...state.history, action.payload]
            history = history.filter(x => x.adId)
            history = orderBy(history, ['date'], ['desc'])

            history = uniqBy(history, function (e) {
                return e.adId + e.userNumber + e.buyerId;
            });
            var hasCall = history.findIndex(x=>x.isRead == false);
            return {
                hasCall: hasCall > -1,
                history: history
            }
        case ACTIONS.CALL_HISTORY_REMOVE_OLD:
            return {
                ...state,
                history: state.history.filter(call => call.id !== action.payload.id && call.adId)
            }
        default: return state;
    }
}

export default oldCallHistoryReducer;