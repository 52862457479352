import { ACTIONS } from '../utils/actions';

const postAdTutorialReducer = (state = false, action) => {
    switch (action.type) {
        case ACTIONS.AD_TUTORIAL:
            return action.payload;
        default: return state;
    }
}

export default postAdTutorialReducer;