import { ACTIONS } from '../utils/actions';

const sellerTutorialReducer = (state = false, action) => {
    switch (action.type) {
        case ACTIONS.SELLER_TUTORIAL:
            return action.payload;
        default: return state;
    }
}

export default sellerTutorialReducer;