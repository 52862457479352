import { uniqBy } from '@s-libs/micro-dash';
import { ACTIONS } from '../utils/actions';

const favoriteReducer = (state = { favorites: [] }, action) => {
    switch (action.type) {
        case ACTIONS.FAVOURITE_ADD:

            var newFav = [...state.favorites, action.payload];
            newFav = uniqBy(newFav, function (e) {
                return e.id;
            });
            return {
                ...state,
                favorites: newFav
            }


        case ACTIONS.FAVOURITE_REMOVE:
            {
                return {
                    ...state,
                    favorites: state.favorites.filter(todo => todo.id !== action.payload.id)
                }
            }
        case ACTIONS.FAVOURITE_REMOVE_ALL: {
            return { favorites: [] }
        }
        case ACTIONS.FAVORITE_UPDATE_STATUS:
            return action.payload
        default: return state;
    }
}

export default favoriteReducer;