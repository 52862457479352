import { ACTIONS } from '../utils/actions';

const addressDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.ADDRESS_DETAILS:
            return {...state, ...action.payload};
        default: return state;
    }
}

export default addressDetailsReducer;