import { ACTIONS } from '../utils/actions';

const authReducer = (state = null, action) => {
    switch (action.type) {
        case ACTIONS.AUTH:
            return action.payload;
        default: return state;
    }
}

export default authReducer;