import { ACTIONS } from '../utils/actions';


const systemNotificationReducer = (state = [], action) => {
    switch (action.type) {
        case ACTIONS.SYSTEM_NOTIFICATION:
            return action.payload;
        case ACTIONS.READ_SYSTEM_NOTIF:
            var index = state.findIndex(x => x.id === action.payload.id)
            var notifs = Object.assign([], state)

            if (index > -1) {
                notifs[index].isVisit = true
            }
            return notifs
        default: return state;
    }
}

export default systemNotificationReducer;