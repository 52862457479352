import { ACTIONS } from '../utils/actions';

const changeLocationReducer = (state = '', action) => {
    switch (action.type) {
        case ACTIONS.CHANGE_LOCATION:
            return action.payload;
        default: return state;
    }
}

export default changeLocationReducer;