import { ACTIONS } from '../utils/actions';

const signUpReducer = (state = false, action) => {
    switch (action.type) {
        case ACTIONS.SIGN_UP:
            return action.payload
        default: return state;
    }
}

export default signUpReducer;