import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import store from "./store";

import * as serviceWorker from './serviceWorker';
import { ACTIONS } from "./utils/actions";
import { Helmet } from "react-helmet";
// import 'babel-polyfill';
import AppLoading from "./components/AppLoading";

const LandingPage = React.lazy(() => import(/* webpackPrefetch: true */'./LandingPage'))
// moment.tz.setDefault();
// moment().tz(process.env.REACT_APP_REGION).format();
let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<AppLoading />} persistor={persistor}>
      <Helmet>
        <title>ChiccaChicca</title>
        <meta name="description" content="Using real-time availability &amp; geolocation tracking, ChiccaChicca enables clients to discover in seconds which escorts are immediately available and how far away they are. Escorts can post profiles for free and only pay when they accept clients’ phone calls or reply to their messages. " />
        <link rel="canonical" href="https://www.chiccachicca.com/" />
        <meta name="og:title" content="ChiccaChicca" />
        <meta name="og:site_url" content="https://www.chiccachicca.com/" />
        <meta name="og:description"
          content="Using real-time availability &amp; geolocation tracking, ChiccaChicca enables clients to discover in seconds which escorts are immediately available and how far away they are. Escorts can post profiles for free and only pay when they accept clients’ phone calls or reply to their messages. " />

        <meta name="twitter:title" content="ChiccaChicca" />
        <meta name="twitter:description"
          content="Using real-time availability &amp; geolocation tracking, ChiccaChicca enables clients to discover in seconds which escorts are immediately available and how far away they are. Escorts can post profiles for free and only pay when they accept clients’ phone calls or reply to their messages. " />

      </Helmet>
      <React.Suspense fallback={<AppLoading isFirstUse={true} />}>
        <LandingPage />
      </React.Suspense>
    </PersistGate>
  </Provider >,
  document.getElementById("root")
);


serviceWorker.register({
  onSuccess: () => store.dispatch({ type: ACTIONS.SW_INIT }),
  onUpdate: registration => {
    store.dispatch({ type: ACTIONS.SW_UPDATE, payload: registration })
  }
});