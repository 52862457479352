import { ACTIONS } from '../utils/actions';


const mapReducer = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.MAP:
            return action.payload;
        default: return state;
    }
}

export default mapReducer;