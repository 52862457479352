import { ACTIONS } from "../utils/actions";

const addProfileImagePromptReducer = (state = {isFromLoginScreen : false}, action) => {
  switch (action.type) {
    case ACTIONS.ADD_PROFILE_IMAGE_PROMPT:
      return action.payload;
    default:
      return state;
  }
};

export default addProfileImagePromptReducer;

export const addProfileImagePrompt = (payload) => {
  return {
    type: ACTIONS.ADD_PROFILE_IMAGE_PROMPT,
    payload: payload,
  };
};
