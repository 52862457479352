import { ACTIONS } from '../utils/actions';

const selectedNotifReducer = (state = '', action) => {
    switch (action.type) {
        case ACTIONS.SELECTED_NOTIF:
            return action.payload;
        default: return state;
    }
}

export default selectedNotifReducer;