import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import reducers from "../reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "topUpReducer",
    "smsReducer",
    "agoraReducer",
    "callHistoryReducer",
    "receivedMessageReducer",
    "progressReducer",
    "sessionReducer",
    "selectedMessageReducer",
    "selectedReducer",
  ],
  whitelist: [
    "addressReducer",
    "addToHomeReducer",
    "backgroundFetchReducer",
    "tutorialReducer",
    "favoriteReducer",
    "adListReducer",
    "requestReducer",
    "settingReducer",
    "addressDetailsReducer",
    "balanceReducer",
    "balanceAlertReducer",
    "userAdsReducer",
    "authReducer",
    "selectUserAdReducer",
    "currentUserReducer",
    "genderModalReducer",
    "welcomeMessageReducer",
    "sellerVisitAdReducer",
    "selectedGenderReducer",
    "systemNotificationReducer",
    "mapReducer",
    "timeDiscrepancyReducer",
    "postAdTutorialReducer",
    "buyerInfoReducer",
    "signUpReducer",
    "locationPermissionReducer",
    "serviceSettingReducer",
    "adListFilterReducer",
    "disclaimerReducer",
    "addProfileImagePromptReducer",
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);
export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: [thunk],
});
