import { ACTIONS } from '../utils/actions';

const requestReducer = (state = {request : null , result : null,requestTime : "",isCustomLocation : false}, action) => {
    switch (action.type) {
        case ACTIONS.REQUEST:
            return action.payload;
        default: return state;
    }
}

export default requestReducer;