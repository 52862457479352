import { ACTIONS } from '../utils/actions';


const timeDiscrepancyReducer = (state = 0, action) => {
    switch (action.type) {
        case ACTIONS.CURRENT_TIME:
            return action.payload;
        default: return state;
    }
}

export default timeDiscrepancyReducer;