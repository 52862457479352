import { ACTIONS } from '../utils/actions';

const initState = {
    isShow : true,
    content : {}
}
const notificationReducer = (state = initState, action) => {
    switch (action.type) {
        case ACTIONS.NOTIFICATION:
            return action.payload;
        default: return state;
    }
}

export default notificationReducer;