import { ACTIONS } from '../utils/actions';

const balanceAlertReducer = (state = { openDialog: false }, action) => {
    switch (action.type) {
        case ACTIONS.BALANCE_ALERT:
            return action.payload;
        default: return state;
    }
}

export default balanceAlertReducer;