import { ACTIONS } from '../utils/actions';

const selectedMessageReducer = (state = {userNumber  : '', adId : ''}, action) => {
    switch (action.type) {
        case ACTIONS.SELECT_MESSAGE:
            return action.payload;
        default: return state;
    }
}

export default selectedMessageReducer;