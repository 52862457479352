import { ACTIONS } from '../utils/actions';

const agoraReducer = (state = {message : {},call : {}}, action) => {
    switch (action.type) {
        case ACTIONS.AGORA:
            return { ...state, ...action.payload };
        default: return state;
    }
}

export default agoraReducer;