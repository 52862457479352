import { ACTIONS } from '../utils/actions';

const chatMessageReducer = (state = { isRead: false, isShow: false }, action) => {
    switch (action.type) {
        case ACTIONS.CHAT_MESSAGE:
            return action.payload;
        default: return state;
    }
}

export default chatMessageReducer;